.section-recruit {
  padding: 100px 0 200px;

  @include mobile {
    padding: 30px 0 100px;
  }

  .headline {
    margin-bottom: 80px;

    @include mobile {
      margin-bottom: 40px;
    }

    &__title {
      font: {
        size: 2.8rem;
      }
      letter-spacing: 0.1rem;
      text-align: justify;

      @include mobile {
        font-size: 2rem;
      }
    }
  }

  .intro {
    font-size: 1.6rem;
    padding: 42px 30px;
    border: {
      top: 1px solid $color-gold-300;
      bottom: 1px solid $color-gold-300;
    }

    @include mobile {
      font-size: 1.4rem;
      padding: 20px 14px;
    }
  }

  .recruit-list {
    margin-top: 80px;
    font-size: 1.6rem;
    line-height: 2.2;
    border-top: 1px dotted $color-gold-400;

    @include mobile {
      font-size: 1.3rem;
      margin-top: 40px;
    }

    &__item {
      border-bottom: 1px dotted $color-gold-400;
      display: flex;
    }

    &__term {
      width: 30%;
      padding: 30px;

      @include mobile {
        padding: 20px 7px 20px 14px;
      }
    }

    &__desc {
      width: 70%;
      padding: 30px;

      @include mobile {
        padding: 20px 14px 20px 7px;
      }
    }
  }

  .contact {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      margin-top: 60px;
      // display: block;
    }

    &__intro {
      line-height: 2;
      font-size: 1.6rem;

      @include mobile {
        font-size: 1.4rem;
      }
    }

    &__tel {
      margin-top: 60px;

      @include mobile {
        margin-top: 40px;
      }
    }
  }

  .button {
    min-height: 80px;
    max-width: 450px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding: 1em;
    color: $color-wht;
    background-color: $color-orange-500;
    transition: opacity 0.4s;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    @include mobile {
      min-height: 50px;
      max-width: 350px;
      padding: 0.8em;
      font-size: 1.6rem;
    }
  }
}
