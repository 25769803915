.section-post {
  padding: 150px 0 200px;
  color: $color-brown-500;

  @include mobile {
    padding: 80px 0 100px;
  }

  .headline {
    &__sub {
      margin-bottom: 16px;
      display: flex;

      > * + * {
        margin-left: 20px;
      }

      @include mobile {
        margin-bottom: 11px;
      }
    }

    &__title {
      margin-bottom: 40px;
      font: {
        size: 2.8rem;
      }
      letter-spacing: 0.1rem;
      text-align: justify;

      @include mobile {
        margin-bottom: 25px;
        font-size: 2rem;
      }
    }

    &__img {
      position: relative;
      padding-top: calc(100% * 2 / 3);

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .content {
    padding-top: 70px;
    padding-bottom: 150px;

    @include mobile {
      padding-top: 40px;
      padding-bottom: 75px;
    }

    > *:not(:last-child) {
      margin-bottom: 60px;

      @include mobile {
        margin-bottom: 40px;
      }
    }

    > .heading:not(:last-of-type) {
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    > .button-container:not(:last-of-type) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    > .spacer {
      margin-bottom: 0;
    }
  }

  div[id^='attachment'] {
    max-width: 100%;
  }

  .lead {
    font-size: 1.6rem;
    padding: {
      top: 24px;
      bottom: 24px;
    }
    border: {
      top: 1px solid $color-gold-300;
      bottom: 1px solid $color-gold-300;
    }

    @include mobile {
      font-size: 1.4rem;
      padding: {
        top: 13px;
        bottom: 13px;
      }
    }

    &__text {
      line-height: 2;
    }
  }

  .heading {
    &__title {
      font: {
        size: 2.6rem;
        weight: 500;
      }

      @include mobile {
        font-size: 2rem;
      }

      &_decoration {
        padding-bottom: 25px;
        position: relative;

        @include mobile {
          padding-bottom: 13px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 60px;
          height: 2px;
          background: $color-orange-400;
        }
      }

      &_small {
        font-size: 2.2rem;

        @include mobile {
          font-size: 1.8rem;
        }
      }
    }
  }

  .paragraph {
    &__text {
      font-size: 1.6rem;

      p {
        line-height: 2;
      }

      @include mobile {
        font-size: 1.4rem;
      }

      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        text-decoration: revert;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &_small {
        font-size: 1.4rem;

        @include mobile {
          font-size: 1.2rem;
        }
      }
    }
  }

  .media {
    &__img-wrapper {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 1%;
      float: left;

      @include mobile {
        width: 100%;
        margin: {
          right: 0;
          bottom: 15px;
        }
        float: none;
      }

      &_reverse {
        margin: {
          right: 0;
          left: 4%;
        }
        float: right;

        @include mobile {
          margin-left: 0;
          float: none;
        }
      }
    }

    &__text {
      font-size: 1.4rem;
      line-height: 2;
    }
  }

  .unorder-list {
    font-size: 1.6rem;
    line-height: 1.8;
    padding-left: 5px;

    @include mobile {
      font-size: 1.4rem;
      padding-left: 2px;
    }

    &__list {
      padding-left: 1em;
      list-style-type: revert;
    }

    &__item {
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }

      &::marker {
        color: $color-orange-400;
      }
    }
  }

  .order-list {
    font-size: 1.6rem;
    line-height: 1.8;
    padding-left: 5px;

    @include mobile {
      font-size: 1.4rem;
      padding-left: 2px;
    }

    &__list {
      padding-left: 1.3em;
      list-style-type: revert;
    }

    &__item {
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }
  }

  .table {
    border-collapse: collapse;
    width: 100%;

    &__title {
      position: relative;
      font: {
        size: 1.8rem;
      }
      margin-bottom: 40px;
      text-align: left;
      padding-left: 1.8em;
      line-height: 1.8;

      @include mobile {
        font-size: 1.5rem;
        flex-basis: 25px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: $color-orange-500;
      }
    }

    &__body {
      background-color: $color-wht;
      text-align: justify;

      @include mobile {
        text-align: center;
        border: 1px solid $color-beige-300;
      }
    }

    &__item {
      border-top: 1px solid $color-gold-300;
    }

    .table__item:last-of-type {
      border-bottom: 1px solid $color-gold-300;
    }

    &__head {
      padding: 1.5em 2em;
      width: 30%;
      font-size: 1.6rem;
      background-color: $color-beige-300;
      color: $base-font-color;
      font-weight: 500;

      @include mobile {
        display: block;
        width: auto;
        font-size: 1.4rem;
        padding: 1.2em;
      }
    }

    &__text {
      padding: 2rem 2.5rem;
      font-size: 1.4rem;
      width: 70%;
      line-height: 2;

      @include mobile {
        display: block;
        font-size: 1.2rem;
        padding: 1.5rem 2.2rem;
        text-align: justify;
        width: auto;
      }
    }
  }

  .figure {
    &__img-wrapper {
      margin-bottom: 16px;

      @include mobile {
        margin-bottom: 10px;
      }

      img {
        width: 100%;
      }
    }

    &__caption {
      font-size: 1.4rem;

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  .movie {
    &__content-wrapper {
      position: relative;
      padding-top: calc(100% * 9 / 16);
      margin-bottom: 16px;

      @include mobile {
        margin-bottom: 10px;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__caption {
      font-size: 1.4rem;

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  .map {
    &__content-wrapper {
      position: relative;
      padding-top: calc(100% * 9 / 16);
      margin-bottom: 16px;

      @include mobile {
        margin-bottom: 10px;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__caption {
      font-size: 1.4rem;

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  .button {
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 30px;

      @include mobile {
        gap: 15px;
      }

      &_2cols {
        flex-direction: row;
      }
    }

    min-height: 80px;
    max-width: 450px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding: 1em;
    color: $color-wht;
    background-color: $color-orange-500;
    transition: opacity 0.4s;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    @include mobile {
      min-height: 50px;
      max-width: none;
      padding: 0.8em;
      font-size: 1.6rem;
    }
  }

  .article {
    border: 1px solid $color-gold-500;
    background: $color-wht;
    padding: 40px;
    display: flex;
    align-items: center;

    @include mobile {
      display: block;
      padding: 30px;
    }

    &__img-wrapper {
      width: 250px;
      margin-right: 30px;
      flex-shrink: 0;

      @include mobile {
        width: auto;
        margin: {
          right: 0;
          bottom: 25px;
        }
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 1.8rem;
      text-align: justify;
      line-height: 1.8;

      @include mobile {
        font-size: 1.6rem;
      }
    }

    &__text {
      font-size: 1.4rem;

      p {
        line-height: 2;
      }

      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        text-decoration: revert;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .bottom {
    padding-bottom: 266px;

    @include mobile {
      padding-bottom: 100px;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    padding: 40px 0;
    border: {
      top: 1px solid $color-gold-300;
      bottom: 1px solid $color-gold-300;
    }
    position: relative;

    @include mobile {
      font-size: 1.4rem;
      padding: 25px 0;
    }

    &__prev,
    &__next {
      a {
        padding: 1em;
        display: block;
        transition: opacity 0.4s;

        &:hover,
        &:focus {
          opacity: 0.7;
        }
      }
    }

    &__back {
      padding: 1em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
}
