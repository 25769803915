.section-info {
  padding: 100px 0 200px;

  @include mobile {
    padding: 30px 0 100px;
  }

  .stack {
    color: $color-brown-500;

    &__link {
      position: relative;
      display: flex;
      padding: 40px 0;
      transition: transform 0.5s;

      &:hover,
      &:focus {
        transform: scale(0.99);

        .stack__arrow img {
          transform: translateX(10px);
        }
      }

      @include mobile {
        display: block;
      }
    }

    &__img {
      width: 35%;
      padding-top: calc(30% * 2 / 3);
      position: relative;

      @include mobile {
        width: auto;
        padding-top: calc(100% * 2 / 3);
        margin-bottom: 25px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }

    &__body {
      width: 65%;
      padding: 4% 160px 0 60px;
      position: relative;

      @include mobile {
        width: auto;
        padding: 0 15px 20px;
      }
    }

    &__term {
      display: flex;
      margin-bottom: 20px;

      @include mobile {
        margin-bottom: 10px;
      }
    }

    &__time {
      margin-right: 1em;
    }

    &__title {
      font-size: 1.6rem;
      line-height: 2;
      text-align: justify;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      @include mobile {
        font-size: 1.4rem;
      }
    }

    &__arrow {
      width: 40px;
      position: absolute;
      top: 50%;
      right: 60px;
      transform: translate(-50%, -50%);

      @include mobile {
        width: 30px;
        top: auto;
        right: 50%;
        bottom: 10px;
        transform: translate(50%, 50%);
      }

      img {
        transition: transform 0.5s;
      }
    }

    &__line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-beige-300;
    }
  }

  .pagination {
    margin-top: 40px;
    color: $color-brown-500;

    @include mobile {
      margin-top: 20px;
    }
  }
}
