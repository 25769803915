.wrap {
  overflow: hidden;
  background-color: $color-beige-100;
}

.section-404 {
  color: $color-brown-500;

  .content {
    padding: 150px 0 300px;

    @include mobile {
      padding: 60px 0 150px;
    }
  }

  .notfound {
    &__text {
      font-size: 1.6rem;
      text-align: center;
      letter-spacing: 0.05em;

      @include mobile {
        font-size: 1.4rem;
        text-align: justify;
      }
    }

    .link-area {
      text-align: center;
      margin-top: 60px;

      @include mobile {
        // margin-top: 40px;
      }

      .link {
        background-color: $color-orange-400;
        color: $color-wht;
        border: none;
      }
    }
  }
}
